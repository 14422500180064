<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <validation-observer ref="addSallingValidation">
      <b-card class="product-add-wrapper p-1">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <validation-provider #default="{ errors }" name="Tên Khách hàng" rules="required">
                <h5 class for>
                  Tên Khách hàng
                  <span class="text-danger">*</span>
                </h5>

                <b-form-input v-model="customerInfo.name" placeholder="Nhập tên khách hàng" />
                <small class="text-danger">{{ errors[0] ? 'Nhập tên Khách hàng' : '' }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <validation-provider name="Số điện thoại">
                <h5 class for>
                  Số điện thoại
                  <!-- <span class="text-danger">*</span> -->
                </h5>

                <b-form-input
                  type="number"
                  @blur="handleGetCustomerInfo"
                  v-model="customerInfo.phone"
                  placeholder="Nhập số điện thoại"
                />
                <!-- <small class="text-danger">{{ errors[0] ? 'Nhập tên Khách hàng' : '' }}</small> -->
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group>
              <validation-provider name="Tuổi">
                <h5 class for>
                  Tuổi
                  <!-- <span class="text-danger">*</span> -->
                </h5>

                <b-form-input v-model="customerInfo.age" placeholder="Nhập tuổi" />
                <!-- <small class="text-danger">{{ errors[0] ? 'Nhập tên Khách hàng' : '' }}</small> -->
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <validation-provider name="Giới tính">
                <h5 class for>
                  Giới tính
                  <!-- <span class="text-danger">*</span> -->
                </h5>
                <div class="d-flex mt-1">
                  <b-form-radio v-model="customerInfo.gender" name="nam" :value="1">Nam</b-form-radio>
                  <b-form-radio class="ml-1" v-model="customerInfo.gender" name="nu" :value="2">Nữ</b-form-radio>
                </div>
                <!-- <b-form-input v-model="customerInfo.age" placeholder="Nhập tuổi" /> -->
                <!-- <small class="text-danger">{{ errors[0] ? 'Nhập tên Khách hàng' : '' }}</small> -->
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group>
              <validation-provider name="Địa chỉ">
                <h5 class for>
                  Địa chỉ
                  <!-- <span class="text-danger">*</span> -->
                </h5>

                <b-form-input v-model="customerInfo.address" placeholder="Nhập địa chỉ" />
                <!-- <small class="text-danger">{{ errors[0] ? 'Nhập tên Khách hàng' : '' }}</small> -->
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider name="Nơi chỉ định">
                <h5 class for>
                  Nơi chỉ định
                  <!-- <span class="text-danger">*</span> -->
                </h5>

                <b-form-input
                  v-model="customerInfo.prescribed_clinic"
                  placeholder="Nhập nơi chỉ định"
                />
                <!-- <small class="text-danger">{{ errors[0] ? 'Nhập tên Khách hàng' : '' }}</small> -->
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="Chọn Kho" rules="required">
                <h5 class for>
                  Chọn Kho
                  <span class="text-danger">*</span>
                </h5>

                <v-select
                  placeholder="Chọn kho"
                  id="region-edit-vendor"
                  v-model="inventoryId"
                  label="name"
                  :clearable="false"
                  :disabled="isPrescriptionWaiting||isUpdateSaling"
                  :reduce="option=>option.id"
                  :options="inventories"
                  @input="handleChangeInventory"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <feather-icon icon="ChevronDownIcon" size="20" />
                    </span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] ? 'Vui lòng chọn Kho' : '' }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider name="Ghi chú">
                <h5 class for>Ghi chú</h5>
                <b-form-input v-model="notes" placeholder="Nhập ghi chú" />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <SelectMedicine
              @onUpdateList="handleUpdateListReceiving"
              :listReceiving="listReceiving"
              :inventoryId="inventoryId"
            />
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <b-col md="12 mt-2">
            <h4>Danh sách Thuốc xuất</h4>
            <b-table
              :tbody-tr-class="checkNew"
              responsive
              :items="listReceiving"
              :fields="tableColumns"
              class="cs-table"
            >
              <template #cell(product_code)="data">
                <span>{{ `#${data.item. product_code}`}}</span>
              </template>
              <template #cell(amount)="data">
                <b-form-input type="number" v-model="data.item.amount" />
              </template>
              <template #cell(price_out)="data">
                <!-- <b-form-input v-model="data.item.price_out" /> -->
                <span>{{ appUtils.numberFormat(data.item.price_out) }}</span>
              </template>
              <template #cell(active_ingredient)="data">
                <span>{{ getActiveIngredient(data.item)}}</span>
              </template>
              <template #cell(unit_id)="data">
                <span>{{ getUnit(data.item)}}</span>
              </template>
              <template #cell(expired_date)="data">
                <span>{{ formatExpiredDate(data.item)}}</span>
              </template>
              <template #cell(supplier)="data">
                <span>{{ getSupplierLabel(data.item)}}</span>
              </template>
              <template #cell(action)="data">
                <span class="cursor-pointer" @click="handleDeleteMedicine(data.item)">
                  <feather-icon icon="Trash2Icon" size="24" class="text-danger" />
                </span>
              </template>
            </b-table>
          </b-col>
          <b-col cols="12" class="mt-3 d-flex align-items-center justify-content-end">
            <b-button
              @click="handleSubmit"
              :disabled="loading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
            >{{ isUpdateSaling?'Cập nhật phiếu xuất':'Thêm phiếu xuất' }}</b-button>
            <b-button
              :disabled="loading"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              @click="handleBack"
              variant="outline-secondary"
            >Thoát</b-button>
          </b-col>
        </b-row>
      </b-card>
    </validation-observer>
  </b-overlay>
</template>

<script>
import Ripple from "vue-ripple-directive";
import SelectMedicine from "./SelectMedicine.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { required } from "@validations";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import appUtils from "@/utils/appUtils";
import { SALE_TYPE, PAYMENT_TYPE } from "@/utils/constant";

const formaterCurrency = new Intl.NumberFormat("en-US");

const tableColumns = [
  { key: "product_code", label: "Mã thuốc", sortable: false },
  {
    key: "name",
    label: "Tên thuốc",
    sortable: false
  },
  { key: "active_ingredient", label: "Hoạt chất", sortable: false },
  { key: "unit_id", label: "Đơn vị", sortable: false },
  {
    key: "qty",
    label: "Tồn kho",
    sortable: false
  },
  // {
  //   key: "avail_qty",
  //   label: "Khả dụng",
  //   sortable: false
  // },
  {
    key: "amount",
    label: "Số lượng",
    sortable: false,
    thStyle: { width: "15%" }
  },
  // {
  //   key: "price_out",
  //   label: "Giá bán",
  //   sortable: false
  // },
  // {
  //   key: "registration_number",
  //   label: "SĐK",
  //   sortable: false
  // },
  // {
  //   key: "lot_input_number",
  //   label: "Số lô",
  //   sortable: false
  // },
  // { key: "expired_date", label: "Hạn sử dụng", sortable: false },
  // { key: "supplier", label: "Nhà cung cấp", sortable: false },
  { key: "action", label: "", sortable: false, thStyle: { width: "2%" } }
];

export default {
  name: "AddSaling",
  components: {
    SelectMedicine,
    ValidationObserver,
    ValidationProvider,
    vSelect
  },
  directives: {
    Ripple
  },
  data() {
    return {
      loading: false,
      listReceiving: [],
      tableColumns,
      productSuppliers: [],
      supplierInfo: null,
      required,
      formaterCurrency,
      appUtils,
      inventories: [],
      inventoryId: null,
      customerInfo: {},
      salingDetail: {},
      notes: ""
    };
  },
  computed: {
    isPrescriptionWaiting() {
      return !!this.$route.query.prescriptionId;
    },
    isUpdateSaling() {
      return !!this.salingDetail?.id;
    },
    isUpdatePrescriptionWaiting() {
      return !!this.salingDetail?.prescription_id;
    }
  },
  watch: {
    $route() {
      this.getPrescriptionDetail();
      this.getSalingDetail();
    }
  },
  created() {
    this.fetchSupplier();
    this.fetchPharmaInventories();
    this.getPrescriptionDetail();
    this.getSalingDetail();
  },
  methods: {
    totalPrice() {
      if (this.listReceiving.length > 0) {
        const arrRowsSum = this.listReceiving.map(x => {
          const realPrice = x.price_out * (1 + x.vat_out / 100);
          return this.formaterCurrencyToNumber(realPrice) * x.amount;
        });
        const total = arrRowsSum.reduce((a, b) => a + b, 0);
        return this.formaterCurrency.format(total);
      } else {
        return 0;
      }
    },
    handleCheckMedicine() {
      const isAllAmount = this.listReceiving?.every(
        item => item?.amount && Number(item?.amount) <= item?.qty
      );

      if (!isAllAmount) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Vui lòng nhập đủ số lượng và không được vượt quá khả dụng",
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });

        return false;
      }

      // const listExpiredDate = this.listReceiving
      //   .filter(item => {
      //     const expiredDate = this.formatMoment(item);
      //     const formatDate = moment(expiredDate)
      //       .utc()
      //       .unix();
      //     const currentDate = moment()
      //       .utc()
      //       .unix();

      //     return currentDate > formatDate;
      //   })
      //   ?.map(item => item.name);

      // if (listExpiredDate.length > 0) {
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: `Bạn không thể tạo phiếu xuất do ${listExpiredDate.join(
      //         ","
      //       )} đã quá hạn sử dụng`,
      //       icon: "AlertTriangleIcon",
      //       variant: "danger"
      //     }
      //   });

      //   return false;
      // }

      return true;
    },
    createSaling() {
      this.$refs.addSallingValidation.validate().then(success => {
        if (success) {
          const isValid = this.handleCheckMedicine();
          if (!isValid) return;

          this.loading = true;

          const payload = {
            notes: this.notes,
            customer_name: this.customerInfo.name,
            customer_address: this.customerInfo.address,
            customer_phone: this.customerInfo.phone,
            customer_age: Number(this.customerInfo.age),
            customer_gender: this.customerInfo.gender,
            prescribed_clinic: this.customerInfo.prescribed_clinic,
            // total_amount: this.formaterCurrencyToNumber(this.totalPrice()),
            pharma_inventory_id: this.inventoryId,
            sale_type: this.isPrescriptionWaiting
              ? SALE_TYPE.patient
              : SALE_TYPE.customer,
            detail_sale: this.listReceiving.map(x => ({
              product_id: x.id,
              qty: parseInt(x.amount)
              // sale_price: this.formaterCurrencyToNumber(x.price_out)
            }))
          };

          if (this.isPrescriptionWaiting) {
            payload.prescription_id = Number(this.$route.query.prescriptionId);
          }

          this.$store
            .dispatch("saling/addSaling", payload)
            .then(res => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Tạo phiếu xuất thành công",
                  icon: "CheckCircleIcon",
                  variant: "success"
                }
              });

              this.$router.replace({
                name: "SalingDetail",
                params: {
                  id: res.data?.id
                }
              });
            })
            .catch(e => {
              console.log(e);
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Lỗi khi tạo phiếu xuất",
                  icon: "AlertTriangleIcon",
                  variant: "danger"
                }
              });
            });
        }
      });
    },
    async updateSaling() {
      this.$refs.addSallingValidation.validate().then(success => {
        if (success) {
          const isValid = this.handleCheckMedicine();
          if (!isValid) return;
          this.loading = true;

          const params = {
            notes: this.notes,
            customer_name: this.customerInfo.name,
            customer_address: this.customerInfo.address,
            customer_phone: this.customerInfo.phone,
            customer_age: Number(this.customerInfo.age),
            customer_gender: this.customerInfo.gender,
            prescribed_clinic: this.customerInfo.prescribed_clinic,
            // total_amount: this.formaterCurrencyToNumber(this.totalPrice()),
            pharma_inventory_id: this.inventoryId,
            sale_type: this.isUpdatePrescriptionWaiting
              ? SALE_TYPE.patient
              : SALE_TYPE.customer,
            detail_sale: this.listReceiving.map(x => ({
              product_id: x.id,
              qty: parseInt(x.amount)
              // sale_price: this.formaterCurrencyToNumber(x.price_out)
            }))
          };

          if (this.isUpdatePrescriptionWaiting) {
            params.prescription_id = Number(this.salingDetail?.prescription_id);
          }

          this.$store
            .dispatch("saling/updateSaling", {
              id: this.salingDetail?.id,
              params
            })
            .then(res => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Cập nhật phiếu xuất thành công",
                  icon: "CheckCircleIcon",
                  variant: "success"
                }
              });

              this.$router.replace({
                name: "SalingDetail",
                params: {
                  id: res.data?.id
                }
              });
            })
            .catch(e => {
              console.log(e);
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Có lỗi xảy ra khi cập nhật phiếu xuất",
                  icon: "AlertTriangleIcon",
                  variant: "danger"
                }
              });
            });
        }
      });
    },
    handleUpdateListReceiving(value) {
      this.listReceiving.push({
        ...value,
        isNew: true,
        price_out: value.price_out
      });
    },
    checkNew(item, type) {
      if (!item || type !== "row") return;
      if (item.isNew) {
        setTimeout(() => {
          item.isNew = false;
        }, 1000);
        return "table-success";
      }
    },
    fetchSupplier() {
      this.$store
        .dispatch("phamarProductInventory/fetchSuppliers")
        .then(res => {
          this.productSuppliers = res.data.data.map(x => ({
            label: x.Supplier_name,
            value: x.ID
          }));
        });
    },
    formaterCurrencyToNumber(value) {
      return Number(value?.toString().replace(/[^0-9.-]+/g, ""));
    },
    getActiveIngredient(data) {
      return data?.active_ingredient_info?.name || "";
    },
    getUnit(data) {
      return data?.unit_info?.name || "";
    },
    formatPrice(price) {
      return appUtils.numberFormat(price, "VNĐ");
    },
    formatExpiredDate(data) {
      if (
        !Number(data?.expiry_date_day) &&
        !Number(data?.expiry_date_month) &&
        !Number(data?.expiry_date_year)
      )
        return "";

      const date =
        Number(data?.expiry_date_day) && Number(data?.expiry_date_day) < 10
          ? `0${data?.expiry_date_day}/`
          : data?.expiry_date_day
          ? `${data?.expiry_date_day}/`
          : "";

      const month =
        Number(data?.expiry_date_month) && Number(data?.expiry_date_month) < 10
          ? `0${data?.expiry_date_month}/`
          : data?.expiry_date_month
          ? `${data?.expiry_date_month}/`
          : "";
      return `${date}${month}${data?.expiry_date_year || ""}`;
    },
    // formatExpiredDate(data) {
    //   const date =
    //     Number(data?.expiry_date_day) && Number(data?.expiry_date_day) < 10
    //       ? `0${data?.expiry_date_day}/`
    //       : data?.expiry_date_day
    //       ? `${data?.expiry_date_day}/`
    //       : "";

    //   const month =
    //     Number(data?.expiry_date_month) && Number(data?.expiry_date_month) < 10
    //       ? `0${data?.expiry_date_month}/`
    //       : data?.expiry_date_month
    //       ? `${data?.expiry_date_month}/`
    //       : "";
    //   return `${date}${month}${data?.expiry_date_year || ""}`;
    // },
    getSupplierLabel(data) {
      return data?.supplier_info?.supplier_name || "";
    },
    handleDeleteMedicine(medicine) {
      this.listReceiving = this.listReceiving.filter(
        item => item?.id !== medicine?.id
      );
    },
    async fetchPharmaInventories(search) {
      const params = {
        keyword: search,
        page_size: 1000,
        page_num: 1,
        org_id: this.$pharmacyInfo.id
      };

      try {
        this.$store
          .dispatch("inventoryManager/fetchInventories", params)
          .then(response => {
            this.inventories = response.data?.data || [];
            if (!this.$route.params.id && !this.$route.query.prescriptionId) {
              const inventoryLocalStorage = JSON.parse(
                localStorage.getItem("inventoryInfo")
              );

              if (inventoryLocalStorage) {
                this.inventoryId = inventoryLocalStorage.id;
              } else {
                this.inventoryId =
                  this.inventories?.length > 0 ? this.inventories[0]?.id : null;
              }
            }
          });
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi khi lấy dữ liệu kho",
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });
      }
    },
    async getPrescriptionDetail() {
      if (!this.$route.query.prescriptionId) return;

      try {
        this.isLoading = true;

        const response = await this.$store.dispatch(
          "phamarProductInventory/getPrescriptionWaiting",
          this.$route.query.prescriptionId
        );
        const responseData = response.data;
        this.listReceiving = (responseData?.person_medicine || []).map(
          item => ({
            ...item?.pharma_product,
            amount: item?.amount,
            avail_qty: item?.pharma_inventory_product?.avail_qty,
            qty: item?.pharma_inventory_product?.qty
          })
        );
        this.inventoryId = responseData?.pharma_inventory_id;
        this.customerInfo = {
          ...(responseData?.person_info || {}),
          age: this.getAge(responseData?.person_info?.birthday),
          address: this.getAddress(responseData),
          prescribed_clinic: responseData?.prescribed_clinic
        };
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi xảy ra khi lấy dữ liệu",
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });
      } finally {
        this.isLoading = false;
      }
    },
    getAge(birthday) {
      return (
        birthday &&
        (window.moment().diff(birthday, "years", false)
          ? `${window.moment().diff(birthday, "years", false)}`
          : "")
      );
    },
    getAddress(data) {
      let result = "";
      if (data.person_info?.address) {
        result += data.person_info?.address;
      }
      if (data.person_info?.address_district_name) {
        result = result + " " + data.person_info?.address_district_name;
      }
      if (data.person_info?.address_province_name) {
        result = result + " " + data.person_info?.address_province_name;
      }

      return result;
    },
    async getSalingDetail() {
      if (!this.$route.params.id) return;

      try {
        const response = await this.$store.dispatch("saling/getSalingDetail", {
          id: this.$route.params.id
        });
        const responseData = response?.data;

        if (
          [PAYMENT_TYPE[1].value, PAYMENT_TYPE[2].value].includes(
            responseData?.pharma_receipt?.payment_status
          )
        ) {
          this.$router.replace({
            name: "SalingDetail",
            params: { id: this.$route.params.id }
          });
        }

        const groupMedicines = this.handleGroupMedicines(
          responseData?.sale_details
        );

        const dataList = (groupMedicines || [])?.map(item => {
          const realPrice =
            item?.sale_price * (1 + item?.product_info?.vat_out / 100);
          const roundPrice = Math.round(realPrice * 100) / 100;
          return {
            ...item?.product_info,
            id: item?.product_inventory_info?.product_master_id,
            avail_qty: item?.product_inventory_info?.avail_qty,
            qty: item?.available_qty,
            amount: item?.request_qty,
            realPrice: roundPrice,
            total_price: (roundPrice || 0) * (item?.qty || 0)
          };
        });

        this.listReceiving = dataList;

        this.customerInfo = {
          name: responseData?.customer_name,
          age: responseData?.customer_age,
          gender: responseData?.customer_gender,
          address: responseData?.customer_address,
          phone: responseData?.customer_phone,
          prescribed_clinic: responseData?.prescribed_clinic
        };

        this.inventoryId = responseData?.pharma_inventory_id;

        const totalPrice = this.listReceiving?.reduce(
          (total, item) => total + (item?.total_price || 0),
          0
        );

        this.salingDetail = { ...responseData, total_amount: totalPrice } || {};
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi xảy ra khi lấy dữ liệu",
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });
      }
    },

    handleSubmit() {
      this.isUpdateSaling ? this.updateSaling() : this.createSaling();
    },
    handleBack() {
      this.$router.go(-1);
    },
    async handleGetCustomerInfo(event) {
      try {
        const self = this;
        if (!event.target.value) return;

        const params = {
          phone: event.target.value
        };

        const response = await self.$store.dispatch(
          "saling/getCustomerInfo",
          params
        );
        const responseData = response.data;
        if (!responseData) return;

        await self.$bvModal
          .msgBoxConfirm(
            "Khách hàng đã có thông tin lưu trữ, bạn có muốn sử dụng không?.",
            {
              title: "Thông tin khách hàng",
              size: "sm",
              okVariant: "primary",
              okTitle: "Có",
              cancelTitle: "Không",
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true
            }
          )
          .then(value => {
            if (value) {
              self.customerInfo = {
                ...self.customerInfo,
                name: responseData.name,
                age: responseData.age,
                gender: responseData.gender,
                address: responseData.address
              };
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    handleChangeInventory(id) {
      const inventoryInfo = this.inventories.find(item => item?.id === id);
      localStorage.setItem("inventoryInfo", JSON.stringify(inventoryInfo));
    },
    formatMoment(data) {
      if (
        !Number(data?.expiry_date_day) &&
        !Number(data?.expiry_date_month) &&
        !Number(data?.expiry_date_year)
      )
        return "";

      const date =
        Number(data?.expiry_date_day) && Number(data?.expiry_date_day) < 10
          ? `0${data?.expiry_date_day}/`
          : data?.expiry_date_day
          ? `${data?.expiry_date_day}/`
          : "01/";

      const month =
        Number(data?.expiry_date_month) && Number(data?.expiry_date_month) < 10
          ? `0${data?.expiry_date_month}/`
          : data?.expiry_date_month
          ? `${data?.expiry_date_month}/`
          : "01/";
      return `${date}${month}${data?.expiry_date_year || ""}`;
    },
    handleGroupMedicines(dataList) {
      const groupMedicines = dataList?.reduce((list, item) => {
        const index = list.findIndex(
          itemIndex =>
            itemIndex?.product_inventory_info?.product_master_id ===
            item?.product_inventory_info?.product_master_id
        );
        let totalOrders = 0;
        if (index === -1) {
          totalOrders = item.qty;
          list.push({ ...item, amount: totalOrders, data: [item] });
        } else {
          totalOrders = list[index].amount + item.qty;
          list[index].amount = totalOrders;
          list[index].data = list[index].data || [];
          list[index].data.push(item);
        }

        return list;
      }, []);

      return groupMedicines;
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
// @import '@core/scss/vue/libs/swiper.scss';
@import "~swiper/css/swiper.css";
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}
.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
.ql-container {
  min-height: inherit;
}
.vs__dropdown-option--disabled {
  display: none;
}

.is-new {
  background-color: rgba(29, 68, 177, 0.12);
}

// .cs-table table {
//   min-width: 1200px;
// }
</style>
